import React from "react"
import { css } from '@emotion/react'
import { useStaticQuery, graphql } from "gatsby"
import {
FacebookIcon,
LinkedinIcon,
} from "react-share";
import ReactHtmlParser from 'react-html-parser'
import { GatsbyImage } from "gatsby-plugin-image"
import {Trans, Link, useTranslation} from 'gatsby-plugin-react-i18next';
import Slider from "react-slick"
import ReactStars from "react-rating-stars-component";


const settings = {
  arrows: true,
    dots: false,
    customPaging:  i => <button></button>,
    /*centerMode: true,
    centerPadding: "5%",*/
    infinite: true,
    dotsClass: 'reviewdots',
    speed: 500,
    slidesToShow: 1,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToScroll: 1,
  };

const getCurrentYear = () => {
    return new Date().getFullYear(); };

export default function Footer()  {
  const data = useStaticQuery(graphql`
  query {
    file(name: {eq: "logo_FIT"}) {
      childImageSharp {
        gatsbyImageData(formats: WEBP, width: 200)
      }
    }
    wp {
      homepage {
        homepage {
          reviews {
            naamKlant
            sterren
            tekst
          }
        }
      }
      websiteSettings {
        algemeneInfo {
          adres
          btwNummer
          mailadres
          telefoonnummer
          vcaCode
          logoKleur {
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: TRACED_SVG
                  quality: 100
                  formats: [WEBP])
                
              }
            }
          }
          algemeneVoorwaardenB2b {
            localFile {
              publicURL
            }
          }
          algemeneVoorwaardenB2c {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }          
  `)
  return (
    <footer>
      <div css={css`display:flex;align-items:center; justify-content: space-between;min-height:50px; max-width: var(--screen-width)`} style={{ margin: `auto auto` }}>
          <div className="asbitechvolgen" css={css`display:flex; align-items: center; font-size: 0.8rem; height: 2rem;`}><div css={css`margin-right: 0.5rem;`}><Trans>Asbitech volgen:</Trans></div><span><a href="https://www.facebook.com/Asbitech-bv-516038008526255" target="_blank" rel="noopener nofollow"><FacebookIcon className="sharebutton" css={css`margin-right: 0.2rem;`} size={20} round={false}></FacebookIcon></a><a href="https://www.linkedin.com/company/asbitech-bvba/" target="_blank" rel="noopener nofollow"><LinkedinIcon className="sharebutton" size={20} round={false}></LinkedinIcon></a></span> </div><br/>
      </div>
      <div className="footer">
        <div className="footercontainer">
        <div css={css`width: 25%`}>
          <GatsbyImage
            image={data.wp.websiteSettings.algemeneInfo.logoKleur.localFile.childImageSharp.gatsbyImageData}
            alt="logo Asbitech"
            layout="fixed"
            css={css`margin-left: -45px;`}
          />
        {ReactHtmlParser(data.wp.websiteSettings.algemeneInfo.adres)}
        <a href={`tel:${data.wp.websiteSettings.algemeneInfo.telefoonnummer}`}>{data.wp.websiteSettings.algemeneInfo.telefoonnummer}</a><br/>
        <a href={`mailto:${data.wp.websiteSettings.algemeneInfo.mailadres}`}>{data.wp.websiteSettings.algemeneInfo.mailadres}</a><br/><br/>
        {data.wp.websiteSettings.algemeneInfo.btwNummer}<br/>
        {data.wp.websiteSettings.algemeneInfo.vcaCode}
        </div>
        <div css={css`width: 25%;`}>
            <div css={css`height: 175px`}>
            <h1><Trans>Diensten</Trans></h1>
            <p><Link to="/diensten"><Trans>Asbestverwijdering</Trans></Link><br/>
            <Link to="/diensten"><Trans>Afbraakwerken</Trans></Link><br/>
            <Link to="/diensten"><Trans>Particuliere begeleiding</Trans></Link></p>
            </div>
            <div css={css`border-bottom: 3px solid var(--background-color); width: 70%; margin-bottom: 1rem;`}/>
            <div>
            <h1><Trans>Over Asbitech</Trans></h1>
            <p><Link to="/over-ons#certificering"><Trans>Certificering</Trans></Link><br/>
            <Link to="/over-ons#over-ons"><Trans>Geschiedenis</Trans></Link></p>
            </div>
        </div>
        <div css={css`width: 25%`}>
          <div css={css`height: 175px`}>
            <h1><Trans>Nieuws</Trans></h1>
            <p><Link to="/nieuws"><Trans>Updates</Trans></Link><br/>
            <Link to="/nieuws"><Trans>Projecten</Trans></Link></p>
          </div>
          <div css={css`border-bottom: 3px solid var(--background-color); width: 70%; margin-bottom: 1rem;`}/>

          <div>
            <h1><Trans>Jobs</Trans></h1>
            <p><Link to="/jobs#vacatures"><Trans>Vacatures</Trans></Link><br/>
           <Link to="/jobs/spontane-sollicitatie"><Trans>Spontane sollicitatie</Trans></Link></p>
          </div>
        </div>
        <div css={css`width: 25%;`}>

        <div css={css`height: 175px;`}>
           <h1><Trans>Wat onze klanten zeggen</Trans></h1>
           <Slider {...settings}>
                    {data.wp.homepage.homepage.reviews.map(({ index, naamKlant, sterren, tekst }) =>
                ( 
                  <div key={index} css={css`padding-left: 1.5rem;`}>
                     <ReactStars count={5}
                     value={sterren}
                     edit={false}
    size={16}
    activeColor="var(--accent-color)"
  />
                    <p css={css`margin-bottom: 0.5rem`}>{tekst}</p>
                    <div css={css`color: var(--accent-color)`}>- {naamKlant}</div>
                  </div>
                 
            
              ))}
      </Slider>
        </div>
        <div css={css`border-bottom: 3px solid var(--background-color); width: 70%; margin-bottom: 1rem;`}/>

          <div css={css`height: 125px`}>
           <h1><Trans>Contact</Trans></h1>
        <p><Link to="/contact#info"><Trans>Bedrijfsinformatie</Trans></Link><br/>
        <Link to="/over-ons#contactform"><Trans>Contactformulier</Trans></Link></p>
        </div>
        </div>

        </div>
        <div className="footerdisclaimer">
        <div css={css`width: 25%; color: var(--text-color)`}>Copyright © {getCurrentYear()} Asbitech</div>
        <div css={css`width: 25%`}><Link to={data.wp.websiteSettings.algemeneInfo.algemeneVoorwaardenB2b.localFile.publicURL}><Trans>Algemene Voorwaarden B2B</Trans></Link></div>
        <div css={css`width: 25%`}><Link to={data.wp.websiteSettings.algemeneInfo.algemeneVoorwaardenB2c.localFile.publicURL}><Trans>Algemene Voorwaarden B2C</Trans></Link></div>
        <div css={css`width: 25%`}><a href="https://op-gemerkt.be">OP-gemerkt</a></div>
        </div>
    </div>
    <div className="footermobile" >
      <div css={css``}>
          <GatsbyImage
            image={data.wp.websiteSettings.algemeneInfo.logoKleur.localFile.childImageSharp.gatsbyImageData}
            alt="logo Asbitech"
            layout="fixed"
            css={css`margin-left: -45px;`}
          />
        {ReactHtmlParser(data.wp.websiteSettings.algemeneInfo.adres)}
        <a href={`tel:${data.wp.websiteSettings.algemeneInfo.telefoonnummer}`}>{data.wp.websiteSettings.algemeneInfo.telefoonnummer}</a><br/>
        <a href={`mailto:${data.wp.websiteSettings.algemeneInfo.mailadres}`}>{data.wp.websiteSettings.algemeneInfo.mailadres}</a><br/><br/>
        {data.wp.websiteSettings.algemeneInfo.btwNummer}<br/>
        {data.wp.websiteSettings.algemeneInfo.vcaCode}
        </div>
        </div>
    </footer>
  )
  }

